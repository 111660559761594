@import './../Components/css/variables.scss';

.container {
    margin-top: 100px;
    padding: 0 10% 50px;

    @media (max-width: 767px) {
        margin-top: 35px;
    }
}

.font {
    h1 {
        font-family: $font-heading;
        font-size: 60px;
        z-index: 2;
        margin-bottom: -50px;

        @media (max-width: 767px) {
            font-size: 48px;
        }
    }

    p {
        font-family: $font-paragraph;
        font-size: 18px;
        margin-bottom: 0;
    }
};

