//Fonts:
$font-heading: 'Urbanist', sans-serif;
$font-paragraph: 'Poppins', sans-serif;
$font-logo-bold: 'League Spartan', sans-serif;
$font-logo-light: 'Quicksand', sans-serif;

//Colors:
$text-dark: #1c101e;
$text-light: #fff;

$background-white: #ffffff;
$background-grey: #efefef;
$background-purple: #b89fbc;
$primary-color: #e4bca5;
$secondary-color: #c4ddc0;
$accent-color: #4a7187;