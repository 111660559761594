body {
    background-color: #000;
}

.App {
    position: relative;
    min-height: 100vh;

    @media (min-width: 768px) and (max-width: 991px) {
        min-height: 90vh;
    }
}

Header {
    box-shadow: 0px 3px 4px rgba(239, 234, 231, 0.5);
    position: sticky;
    z-index: 5;
    top: 0;
    background-color: #fff;
}